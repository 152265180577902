const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6974185",
    space_js: "//h5tnfpb1.sztncm.com/source/s_n/static/m_vo_jwpk.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6974187",
    space_js: "//h5tnfpb1.sztncm.com/common/up/o/xqlyr_q.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6974188",
    space_js: "//h5tnfpb1.sztncm.com/production/v-qp/source/yrm/z/static/ss.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6974189",
    space_js: "//h5tnfpb1.sztncm.com/site/w/production/rq-zsn-a/source/tz.js"
  }
];

const Banner = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6974186",
    space_js: "//h5tnfpb1.sztncm.com/common/ton_wpk/production/x_q_n.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native1,
  Home_banner: Banner,
  Detail_inter: Inter,
  Detail_native: Native1,
  Detail_banner: Banner,
  List_native: Native1,
  List_banner: Banner,
  Result_inter: Inter,
  Result_native: Native1,
  Result_banner: Banner,
  Result_native_center1: Native2,
  Result_native_center2: Native3,
};
